import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  handicapRace1Data: {
    data: {
      labels: [],
      dataset: [],
      limitClampLegends: [],
      tabledata: []
    }
  },
};


export const handicapRace1Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HANDICAP_RACE_DATA1:
      return {
        ...state,
        handicapRace1Data: action.payload,
      };
    default:
      return state;
  }
};
