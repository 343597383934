import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  handicapBoatData: {
    data: []
  },
};


export const handicapBoatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HANDICAP_BOAT_DATA:
      return {
        ...state,
        handicapBoatData: action.payload,
      };
    default:
      return state;
  }
};
