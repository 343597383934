import * as React from "react";
import "./main.scss"
import DefaultLayout from "./components/DefaultLayout";
import { LicenseInfo } from '@mui/x-license';

// Set the license key for DataGridPro
LicenseInfo.setLicenseKey("2cd05b83bdb030e94d16aca94fcd98e8Tz05MzM4OSxFPTE3NTEyMTAyMjEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function App() {
  return (

 <DefaultLayout/>

  );
}
