import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  handicapSummary: {
    data: []
  },
};


export const handicapSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HANDICAP_SUMMARY:
      return {
        ...state,
        handicapSummary: action.payload,
      };
    case actionTypes.EMPTY_HANDICAP_SUMMARY:
        return {
          ...state,
          handicapSummary: action.payload,
        };
    default:
      return state;
  }
};
