import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  handicapSelectedBoat: {
    data: null,
  },
};


export const handicapSelectedBoatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_BOAT:
      return {
        ...state,
        handicapSelectedBoat: action.payload,
      };
    default:
      return state;
  }
};
