import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  resultOption1Data: {
    data: {
        "seriesResultOptionId": "",
        "resultBy": "",
        "title": "",
        "handicapId": ""
    },
  },
};


export const handicapResultOption1Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_RESULT_OPTION1:
      return {
        ...state,
        resultOption1Data: action.payload,
      };
    default:
      return state;
  }
};
