import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  handicapSelectedOptions: {
    data: {
      showPlace1: false,
      showPlace2: false,
      showLimit: false,
    }
  },
};


export const handicapSelectedOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_OPTIONS:
      return {
        ...state,
        handicapSelectedOptions: action.payload,
      };
    default:
      return state;
  }
};
