import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  handicapFleetBoatData: {
    data: []
  },
};


export const handicapFleetBoatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HANDICAP_BOAT_DATA_BY_FLEET:
      return {
        ...state,
        handicapFleetBoatData: action.payload,
      };
    default:
      return state;
  }
};
