import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  handicapFleetSummary: {
    data: []
  },
};


export const handicapFleetSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FLEET_HANDICAP_SUMMARY:
        return {
          ...state,
          handicapFleetSummary: action.payload,
        };
    default:
      return state;
  }
};
