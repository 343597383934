import * as actionTypes from "../actions/actionsTypes";

const initialState = {
  resultOption2Data: {
    data: {
        "seriesResultOptionId": "",
        "resultBy": "",
        "title": "",
        "handicapId": ""
    },
  },
};


export const handicapResultOption2Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_RESULT_OPTION2:
      return {
        ...state,
        resultOption2Data: action.payload,
      };
    default:
      return state;
  }
};
