import { legacy_createStore as createStore } from 'redux';
import {thunk} from 'redux-thunk';
import { combineReducers, applyMiddleware } from 'redux';

import {clubReducer} from '../reducers/clubReducer';
import { hcDefsReducer } from '../reducers/hcDefsReducer';
import { hcDefsListReducer } from '../reducers/hcDefsListReducer';
import {userReducer} from "../reducers/userReducer";
import {menuReducer} from "../reducers/menuReducer";
import { sessionReducer } from '../reducers/sessionReducer';
import { scorePointDefListReducer } from '../reducers/scorePointDefListReducer';
import { eventReducer,eventKBReducer,eventOTBReducer } from '../reducers/eventReducer';
import { eventKBListReducer,eventOTBListReducer,eventListReducer } from '../reducers/eventListReducer';
import { seriesKBReducer, seriesOTBReducer, seriesReducer } from '../reducers/seriesReducer';
import { TESEventReducer } from '../reducers/TESEventReducer';
import { TESSeriesReducer } from '../reducers/TESSeriesReducer';
import { TESAssoEventReducer } from '../reducers/TESAssoEventReducer';
import { TESAssoSeriesReducer } from '../reducers/TESAssoSeriesReducer';
import { seriesListReducer } from '../reducers/seriesListReducer';
import { clubListReducer } from '../reducers/clubListReducer';
import { visibilityTypeReducer } from '../reducers/visibilityTypeReducer';
import {logoutReducer} from "../reducers/logoutReducer";
import {refreshtokenReducer} from "../reducers/refreshtokenReducer";
import { scorePenalityReducer } from '../reducers/scorePenalityReducer';
import { penaltyListReducer } from '../reducers/penaltyListReducer';
import { seriesOTBListReducer } from '../reducers/seriesOTBListReducer';
import { standardScorePointDefListReducer } from '../reducers/standardScorePointDefListReducer';
import { raceCalendarReducer } from '../reducers/raceCalendarReducer';
import { raceSeriesReducer } from '../reducers/raceSeriesReducer';
import { incompleteSeriesReducer } from '../reducers/incompleteSeriesReducer';
import {raceSeriesListReducer } from '../reducers/raceSeriesListReducer';
import { raceSessionReducer } from '../reducers/raceSessionReducer';
import { raceEntrantsReducer } from '../reducers/raceEntrantsReducer';
import { TESEntrantsReducer } from '../reducers/TESEntrantsReducer';
import { seriesEntrantsReducer } from '../reducers/seriesEntrantsReducer';
import { entrantsEventListReducer } from '../reducers/entrantsEventListReducer';
import { entrantsSeriesListReducer } from '../reducers/entrantsSeriesListReducer';
import { entrantsSeriesReducer } from '../reducers/entrantsSeriesReducer';
import triggerCSVImportReducer from '../reducers/triggerCSVImportReducer';
import {signedOnReducer} from '../reducers/signedOnReducer';
import { standardClubClassReducer } from '../reducers/standardClubClassReducer';
import { raceResultsReducer } from '../reducers/raceResultsReducer';
import { seriesScoresReducer } from '../reducers/seriesScoresReducer';
import {entrantOffsetReducer} from '../reducers/entrantOffsetReducer';
import {raceSessionOverviewReducer} from '../reducers/raceSessionOverviewReducer';
import {raceSessionDeleteUpdateReducer} from '../reducers/raceSessionDeleteUpdateReducer';
import triggerTESImportReducer from '../reducers/triggerTESImportReducer';
import entrantImportSourceReducer from '../reducers/entrantImportSourceReducer';
import { seriesKBNameListReducer, seriesOTBNameListReducer, seriesNameListReducer }  from '../reducers/seriesNameListReducer';
import { raceSessionNameListReducer } from '../reducers/raceSessionNameListReducer';
import { publishedResultsReducer } from '../reducers/publishedResultsReducer';
import { singleRaceSessionReducer } from '../reducers/singleRaceSessionReducer';
import { HiddenColumnsReducer } from '../reducers/HiddenColumnsReducer';
import { timezoneListReducer } from '../reducers/timzoneListReducer';
import { selectedResultOption1, selectedResultOption2 } from '../actions/actions';
import { handicapResultOption1Reducer } from '../reducers/handicapResultOption1Reducer';
import { handicapResultOption2Reducer } from '../reducers/handicapResultOption2Reducer';
import { handicapBoatReducer } from '../reducers/handicapBoatReducer';
import { handicapRace1Reducer, handicapRaceReducer } from '../reducers/handicapRace1Reducer';
import { handicapSelectedBoatReducer } from '../reducers/handicapSelectedBoatReducer';
import { handicapRace2Reducer } from '../reducers/handicapRace2Reducer';
import { handicapSelectedOptionsReducer } from '../reducers/handicapSelectedOptionsReducer';
import { handicapSummaryReducer } from '../reducers/handicapSummaryReducer';
import { handicapFleetBoatReducer } from '../reducers/handicapFleetBoatReducer';
import { handicapFleetSummaryReducer } from '../reducers/handicapFleetSummaryReducer';

const rootReducer = combineReducers({
  clubData: clubReducer,
  clubList: clubListReducer,
  hcDefsData: hcDefsReducer,
  hcDefsList: hcDefsListReducer,
  userData: userReducer,
  menuData: menuReducer,
  sessionData: sessionReducer,
  scorePointDefsData: scorePointDefListReducer,
  standardScorePoints: standardScorePointDefListReducer,
  eventData: eventReducer,
  eventKBData: eventKBReducer,
  eventOTBData: eventOTBReducer,
  eventList: eventListReducer,
  eventKBList: eventKBListReducer,
  eventOTBList: eventOTBListReducer,
  seriesKBData: seriesKBReducer,
  seriesData: seriesReducer,
  incompleteSeriesData: incompleteSeriesReducer,
  seriesList: seriesListReducer,
  seriesKBNameList: seriesKBNameListReducer,
  seriesOTBNameList: seriesOTBNameListReducer,
  seriesNameList: seriesNameListReducer,
  seriesOTBData: seriesOTBReducer,
  seriesOTBList: seriesOTBListReducer,
  penaltyList: penaltyListReducer,
  scorePenalityDefsData: scorePenalityReducer,
  TESEventData: TESEventReducer,
  TESSeriesData: TESSeriesReducer,
  TESAssoEventData: TESAssoEventReducer,
  TESAssoSeriesData: TESAssoSeriesReducer,
  Data: logoutReducer,
  data : refreshtokenReducer,
  visibilityTypeData: visibilityTypeReducer,
  raceData:raceCalendarReducer,
  raceSeriesList: raceSeriesListReducer,
  raceSeriesData:raceSeriesReducer,
  raceSessionData: raceSessionReducer,
  raceSessionDataForOverview: raceSessionReducer,
  raceSessionNameList: raceSessionNameListReducer,
  singleRaceSessionData: singleRaceSessionReducer,
  raceEntrants:raceEntrantsReducer,
  TESEntrants: TESEntrantsReducer,
  seriesEntrants:seriesEntrantsReducer,
  entrantsEventList:entrantsEventListReducer,
  entrantsSeriesList:entrantsSeriesListReducer,
  entrantsSeriesData:entrantsSeriesReducer,
  clubClassData: standardClubClassReducer,
  triggerCSVImport: triggerCSVImportReducer,
  triggerTESImport: triggerTESImportReducer,
  entrantImportSource: entrantImportSourceReducer,
  signedOn:signedOnReducer,
  offsetEntrantsList:entrantOffsetReducer,
  raceSessionOverviewData:raceSessionOverviewReducer,
  updatedRace:raceSessionDeleteUpdateReducer,
  results:raceResultsReducer,
  scores:seriesScoresReducer,
  offsetResultsList:entrantOffsetReducer,
  publishedResults:publishedResultsReducer,
  allPenalties:penaltyListReducer,
  hiddenColumns: HiddenColumnsReducer,
  timezoneList: timezoneListReducer,
  resultOption1Data: handicapResultOption1Reducer,
  resultOption2Data: handicapResultOption2Reducer,
  handicapBoatData: handicapBoatReducer,
  handicapRace1Data: handicapRace1Reducer,
  handicapRace2Data: handicapRace2Reducer,
  handicapSelectedBoat: handicapSelectedBoatReducer,
  handicapSelectedOptions: handicapSelectedOptionsReducer,
  handicapSummary: handicapSummaryReducer,
  handicapFleetBoatData: handicapFleetBoatReducer,
  handicapFleetSummary: handicapFleetSummaryReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
